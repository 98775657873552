

import React from "react";
import Home from "./Home";

function App() {
  return (
    <div className="w-full min-h-screen font-bodyfont text-textColor bg-dark relative flex flex-col justify-between">
      <div className="w-full flex-grow">
        <Home />
      </div>
    </div>
  );
}

export default App;



